@import "styles/variables";

.date-demo-wrapper {
  flex-direction: row;
  overflow: hidden;
}
.date-flicking {
  overflow: visible;
  box-sizing: content-box;
  height: 375px;
  perspective: 700px;
  transform-style: preserve-3d;
  width: 200px;
}


.date-flicking > .flicking-camera {
  transform-style: preserve-3d;
}

.date-panel {
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
  backface-visibility: hidden;
  font-size: 18px;
  color:  $brownishGray;
  font-weight: 500;
  font-family: $fMontserrat;
}

.date-panel-container {
  z-index: -3;
}

.date-panel-border {
  width: calc(100% - 20px);
  height: 38px;
  position: absolute;
  top: 160px;
  left: 14px;
  border: 3px solid transparent; 
  border-image: $gradientBlue 1;
  border-image-slice: 1; 
  border-radius: 8px;
  z-index: -3;
}

.shadow-top {
  width: 100%;
  height: 140px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(23, 37, 54, 0.02) 0%, rgba(23, 37, 54, 0.00) 100%);
  backdrop-filter: blur(1px);
}
.shadow-bottom {
  width: 100%;
  height: 160px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(23, 37, 54, 0.02) 0%, rgba(23, 37, 54, 0.00) 100%);
  backdrop-filter: blur(1px);
}

.has-text-white {
  color: #fff;
}