@import "src/styles/variables";

.card {
  display: flex;
  width: 120px;
  height: 120px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 60px;
  border: 2px solid  $borderColor;
  background: $inputBg;
  backdrop-filter: blur(3px);

  &:hover {
    background: $lavender ;
  }

  &Selected {
    border: 2px solid #5784F8;
    background: $lavender;
  }
}

.cardsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 39px;
}

.circle {
  width: 36px;
  height: 36px;
  border-radius: 60px;
  border: 2px solid $borderColor;
  background: #F2AC3C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}